import SEO from "@/components/generic/seo";
import React from "react";

export default function NotFound() {
  return (
    <main>
      <SEO title="Helaas, 404" />
      <p>Sorry, deze pagina kan niet worden gevonden</p>
    </main>
  );
}
